<template>
    <div v-if="!isSearchInMobileChat" class="bottom-panel-component" @click.stop>
        <div v-if="replyingMessage || editingMessage" class="q-ml-sm q-mt-sm q-mr-md relative-position">
            <div v-if="replyingMessage" class="editing-message-block">
                <div class="user-name q-pt-xs flex items-center">
                    <q-icon
                        v-if="getRoleIconName(replyingMessage.authorRole)"
                        :name="getRoleIconName(replyingMessage.authorRole)"
                        size="14px"
                        color="success"
                        class="q-mr-xs"
                    />

                    {{ getFullUserName(replyingMessage.author) }}
                </div>

                <MessageText v-if="replyingMessage.text" :message="replyingMessage.text" class="ellipsis q-pr-lg" />
                <div v-else>
                    <template v-if="replyingMessage.files && replyingMessage.files.length > 1">
                        [{{ localize('Файлы') }}]
                    </template>
                    <template v-else>
                        [{{ localize('Файл') }}]
                    </template>
                </div>
            </div>
            <div v-if="editingMessage" class="editing-message-block">
                <div class="font-bold q-mb-xs">{{ localize('Редактирование сообщения') }}</div>
                <MessageText v-if="editingMessage.text" :message="editingMessage.text" class="ellipsis q-pr-lg" />
                <div v-else>
                    <template v-if="editingMessage.files && editingMessage.files.length > 1">
                        [{{ localize('Файлы') }}]
                    </template>
                    <template v-else>
                        [{{ localize('Файл') }}]
                    </template>
                </div>
            </div>
            <q-icon
                name="close"
                size="24px"
                @click="cancelWriteMessage"
                class="cursor-pointer q-mr-sm q-mt-sm absolute-top-right"
            />
        </div>
        <q-field v-if="isArchiveChat" disable class="high-field">
            <div class="q-pa-md text-shade-8">{{ localize('Вы не можете отправлять новые сообщения в этот чат.') }}</div>
        </q-field>
        <div
            v-else
            :class="{ 'row items-center': !isInlineModeChat }"
        >
            <SearchUsers
                ref="searchUsersRef"
                :search-user-name="userNameMention"
                @on-select-user="onSelectUser"
                @on-hide="removeUnusedMention"
            />

            <q-editor
                v-model="newMessage"
                ref="editorRef"
                class="col q-editor-message noto-color-emoji no-border"
                :class="{ 'inline-editor': isInlineModeChat }"
                min-height="56px"
                :toolbar="[]"
                :placeholder="placeholder"
                @paste="onPaste"
                @keydown="onKeydown"
                @keyup="onKeyup"
                @update:model-value="onChangeMessage"
                :aria-label="placeholder"
            />

            <hr v-if="isInlineModeChat" class="q-ma-none">

            <div
                class="flex no-wrap items-center"
                :class="{
                    'row reverse justify-between q-px-md q-py-sm': isInlineModeChat
                }"
            >
                <Icon
                    v-if="editingMessage"
                    name="CheckIcon"
                    key="CheckIcon"
                    :color="ColorValiablesNameEnum.primary"
                    :hover-color="ColorValiablesNameEnum.primary"
                    :class="{ 'chat-icon': !isInlineModeChat }"
                    @click.native="sendMessage"
                    class="cursor-pointer"
                    role="button"
                    tabindex="0"
                    :aria-label="localize('Отправить сообщение')"
                />
                <Icon
                    v-else
                    name="SendIcon"
                    key="SendIcon"
                    :color="(hasTextMessage || messageFiles.length > 0) ? ColorValiablesNameEnum.primary : ColorValiablesNameEnum.shade7"
                    :class="{ 'chat-icon': !isInlineModeChat }"
                    @click.native="sendMessage"
                    role="button"
                    tabindex="0"
                    :aria-label="localize('Отправить сообщение')"
                    class="cursor-pointer"
                />
                <div class="flex no-wrap">
                    <Icon
                        name="EmojiIcon"
                        class="cursor-pointer chat-icon"
                        size="24px"
                        role="button"
                        tabindex="0"
                        :aria-label="localize('Вставить эмоджи')"
                    >
                        <q-menu class="emoji-menu overflow-hidden">
                            <q-list v-close-popup>
                                <EmojiBlock @select="selectEmoji" />
                            </q-list>
                        </q-menu>
                    </Icon>

                    <Icon
                        name="AttachFileIcon"
                        class="cursor-pointer chat-icon"
                        @click.native="isShowFileWindow = true"
                        role="button"
                        tabindex="0"
                        :aria-label="localize('Прикрепить файл')"
                    />

                    <Icon
                        name="CodeIcon"
                        key="CodeIcon"
                        @click.stop.native="insertCode"
                        class="cursor-pointer chat-icon"
                        role="button"
                        tabindex="0"
                        :aria-label="localize('Вставить код')"
                    />
                </div>
            </div>
        </div>

        <uploaded-files
            :files="files"
            :is-mobile-view="isMobileView"
            @on-deleted-file="onDeletedFile"
            @click.native="isShowFileWindow = true"
        />

        <q-dialog v-model="isShowFileWindow" @hide="setFocusToTextField">
            <q-card class="confirm-modal files-modal q-pa-lg">
                <div class="confirm-modal__title q-mb-md">{{ localize('Прикрепить файлы') }}</div>
                <div class="q-mb-md">
                    <Dropzone
                        ref="dropzoneRef"
                        :drop-zone-id="'chatDropzone'"
                        :files="files"
                        :need-to-encode="false"
                        @on-upload-completed="isUploadCompleted = true"
                        @on-added-file="isUploadCompleted = false"
                    />
                </div>
                <div class="text-right">
                    <q-btn
                        flat
                        :label="localize('Отменить')"
                        color="primary"
                        v-close-popup
                    />
                    <q-btn
                        flat
                        :label="localize('Готово')"
                        color="primary"
                        class="q-manual-focusable--focused q-ml-md"
                        @click="attachFiles"
                        :disable="!isUploadCompleted"
                        v-close-popup
                    />
                </div>
            </q-card>
        </q-dialog>
    </div>
</template>

<script lang="ts">
    import Bowser from 'bowser';
    import { debounce, QEditor } from 'quasar';
    import { localize } from 'src/services/LocalizationService';
    import { Common } from 'src/helpers/Common';
    import SearchUsers from './SearchUsers.vue';
    import {
        AccountCurrentInfoResponseModel,
        ChatBaseInfoResponseModel,
        ChatMembersDto,
        ChatMessageDto,
        ChatMessageHubDto,
        ChatMessageType,
        ChatType,
        FileClient,
        ListChatDto,
        MessageFile,
        RoutePageNameEnum,
        UserBaseInfoDto,
    } from 'src/api/ApiClient';
    import Dropzone from 'components/Dropzone';
    import EmojiBlock from 'components/EmojiBlock/EmojiBlock.vue';
    import { IDropzoneFile, IFile } from 'components/Dropzone/interfaces';
    import { DropzoneFile } from 'dropzone';
    import { IChatMessage, IDraftInfo, IDraftMessage } from '../types/interfaces';
    import UploadedFiles from 'components/Chat/components/UploadedFiles.vue';
    import { getApiClientInitialParams } from 'src/api/BaseApiClient';
    import {
        computed,
        defineComponent,
        getCurrentInstance,
        nextTick,
        onBeforeUnmount,
        onMounted,
        PropType,
        ref,
        watch,
    } from 'vue';
    import { ChatPartialHub } from 'src/services/hubs/ChatPartialHub';
    import { chatBus } from 'components/EventBuses';
    import sanitizeHtml from 'sanitize-html';
    import { useRoute } from 'vue-router';
    import { ModeChat } from 'components/Chat/types/enums';
    import MessageText from 'components/Chat/components/MessageText.vue';
    import useChatMembers from 'components/Chat/hooks/useChatMembers';
    import { ColorValiablesNameEnum } from 'components/ui/Icon/interface';
    import DateUtil from 'src/helpers/DateUtil';
    import { useAccountStore } from 'src/store/module-account';
    import { useAuthorizationStore } from 'src/store/module-authorization';
    import { AuthorizationStoreInterface } from 'src/store/module-authorization/state';
    import { useChatStore } from 'src/store/module-chat';
    import { ChatBusEvents } from 'components/EventBuses/emuns';

    export default defineComponent({
        name: 'BottomPanel',

        components: {
            MessageText,
            UploadedFiles,
            Dropzone,
            SearchUsers,
            EmojiBlock
        },

        emits: [
            'on-send-message',
            'on-send-message-error',
            'add-new-message-to-chat',
            'create-chat-and-solution',
            'create-private-chat'
        ],

        props: {
            // Модель базовой информации о чате
            chatInfo: {
                type: Object as PropType<ChatBaseInfoResponseModel | undefined | null>,
                default: undefined
            },
            // Архивный чат, показывается в левой панели на странице чатов
            isArchiveChat: {
                type: Boolean,
                default: false
            },
            // Мобильный вид чата
            isMobileView: {
                type: Boolean,
                default: false
            },
            // Является ли чат чатом решения
            isActivitySolutionChat: {
                type: Boolean,
                default: false
            },
            // Является ли чат чатом обсуждения
            isThreadChat: {
                type: Boolean,
                default: false
            },
            // Передаётся в чате решений
            activityId: {
                type: Number as PropType<number | undefined | null>,
                default: undefined
            },
            // если вид от препода - это студент
            // с которым он ведёт переписку
            // передаётся в чате решений
            student: {
                type: Object as PropType<UserBaseInfoDto | undefined | null>,
            },
            // Данные пользователя для которого нужно будет создать приватный чат
            userDataForNewPrivateChat: {
                type: Object as PropType<UserBaseInfoDto | undefined | null>,
            },
            // Режим отображения чата
            modeChat: {
                type: String,
                default: ModeChat.Inline
            },
        },

        // eslint-disable-next-line max-lines-per-function
        setup(props, context) {
            const $route = useRoute();
            const app = getCurrentInstance();
            const searchUsersRef = ref<typeof SearchUsers | null>(null);
            const editorRef = ref<QEditor | null>(null);
            const dropzoneRef = ref<InstanceType<typeof Dropzone> | null>(null);
            const chatStore = useChatStore();
            const accountStore = useAccountStore();
            const { getRoleIconName } = useChatMembers();

            const newMessage = ref<string>('');
            const userNameMention = ref<string>('');
            const isShowFileWindow = ref<boolean>(false);
            const files = ref<IFile[]>([]);
            const messageFiles = ref<{ id: number, secretKey: string }[]>([]);
            const isUploadCompleted = ref<boolean>(true);
            const newMentionSelector = '#new-user-mention';
            let currentRouteName: string = '';

            // Нужно ли сохранять черновики при обновлении страницы
            // по умолчанию нужно, не нужно в случае логина под другим пользователям ODIN-9662
            let isNeedSaveOnUnloadPage = true;

            const onChangeMessage: () => void = debounce(() => {
                updateTextFieldHeight();
            }, 500);

            const isSelfChat = computed<boolean>(() => {
                return props.chatInfo?.type === ChatType.Self;
            });

            const placeholder = computed<string>(() => {
                return isSelfChat.value ? localize('Введите заметку') : localize('Введите сообщение');
            });

            const isInlineModeChat = computed<boolean>(() => {
                return props.isMobileView || props.modeChat === ModeChat.Inline;
            });

            const chatId = computed<number>(() => {
                return props.chatInfo ? props.chatInfo.id : 0;
            });

            // Находится ли мы на странице звонка
            const isCallPage = computed<boolean>(() => {
                return $route.name === Common.getRouteName(RoutePageNameEnum.CallEnter);
            });

            const isSearchInMobileChat = computed<boolean>(() => {
                return chatStore.searchedMessageIds !== null;
            });

            const editingMessage = computed<ChatMessageDto | null>(() => {
                // Тк сообщение хранится в сторе, а компонент используется в разных местах
                // то проверяем, что мы находится в нужном чате, иначе сообщение появится везде
                if (chatStore.editingMessage && chatStore.editingMessage.chatId === chatId.value) {
                    return chatStore.editingMessage;
                }

                return null;
            });

            const replyingMessage = computed<ChatMessageDto | null>(() => {
                // Тк сообщение хранится в сторе, а компонент используется в разных местах
                // то проверяем, что мы находится в нужном чате, иначе сообщение появится везде
                if (chatStore.replyingMessage && chatStore.replyingMessage.chatId === chatId.value) {
                    return chatStore.replyingMessage;
                }

                return null;
            });

            const mainMessageInThread = computed<ChatMessageDto | null>(() => {
                return chatStore.mainMessageInThread;
            });

            const accountInfo = computed<AccountCurrentInfoResponseModel | null>(() => {
                return accountStore.getAccountInfo;
            });

            const hasTextMessage = computed<boolean>(() => {
                let message = Common.stripTags(newMessage.value.replace(/&nbsp;/gi, '')).trim();
                message = message.replace(/\`{3}/g, '');
                const encodeMessage = decodeURIComponent(encodeURIComponent(message).replace(/%C2%AD/gi, ''));
                return !!encodeMessage.length;
            });

            watch(editingMessage, (message: ChatMessageDto | null) => {
                if (message?.text) {
                    // Заменяем div на span, в теории есдинственные div - это упонимания
                    // но даже если заменим какой-то другой див то это ни на что не повлияет
                    let text = message.text.replace(/<div/g, '<span');
                    text = text.replace(/<\/div>/g, '</span>');
                    newMessage.value = text;

                    setFocusToTextField();
                } else {
                    newMessage.value = '';
                }
            });

            // В чатах-обсуждениях отслеживаем изменения главного сообщения
            // тк черновики надо сохранять и для несозданных обсуждений
            watch(() => mainMessageInThread.value, (newValue, oldValue) => {
                if (props.isThreadChat) {
                    updateDraftMessage(oldValue?.id);
                    initMessageDrafts();
                }
            });

            watch(() => props.chatInfo, () => {
                const isChatPage = $route.name === Common.getRouteName(RoutePageNameEnum.Chat);

                // Для страницы чата вотчер должен отрабатывать только если это не чат решения
                // либо это другая страница или миничат
                // тк для чатов решения и тредов свой вотчер
                if (!props.isThreadChat && ((isChatPage && !props.isActivitySolutionChat) || !isChatPage)) {
                    newMessage.value = '';
                    files.value = [];
                    initMessageDrafts();
                }
            });

            // вотчер для отслеживания изменнений в чатах решений
            // потому что при переключении несозданных чатов хуки жизненнего цикла не отрабатывают
            watch([() => props.activityId, () => props.student], ([newActivityId], [oldActivityId, oldStudent]) => {
                if (props.isActivitySolutionChat) {
                    if (oldStudent && oldActivityId) {
                        updateDraftMessage(Number(oldActivityId.toString() + oldStudent.id.toString()));
                    }

                    if (newActivityId) {
                        newMessage.value = '';
                        files.value = [];
                        initMessageDrafts();
                    }
                }
            });

            // Удалить файл по нажатию на иконку корзины
            function onDeletedFile(secretKey: string): void {
                new FileClient(getApiClientInitialParams()).removeFile(secretKey);

                files.value = files.value.filter((f) => f.secretKey !== secretKey);
                messageFiles.value = messageFiles.value.filter((f) => f.secretKey !== secretKey);
            }

            function cancelWriteMessage(): void {
                newMessage.value = '';
                chatStore.editingMessage = null;
                chatStore.replyingMessage = null;
                removeUnusedMention();
            }

            // Проверка и обработка нажатия стрелок вверх и вниз
            function handlePressArrows(event: KeyboardEvent): void {
                const isShowUsersMentionList = searchUsersRef.value?.isVisible;
                const isPressArrow = (event.key === 'ArrowDown' || event.key === 'ArrowUp');
                if (isShowUsersMentionList && isPressArrow) {
                    event.preventDefault();
                }
            }

            function onKeydown(event: KeyboardEvent): void {
                if (event.key === 'Escape') {
                    cancelWriteMessage();
                    return;
                }

                if (event.shiftKey && event.key === 'Enter') {
                    // Отключил дефолтное поведение, потому что после Shift-enter каретка становится в позицию 2
                    event.preventDefault();
                    insertBrToMessage();
                    removeUnusedMention();
                }

                if (event.ctrlKey && event.key === 'Enter') {
                    event.preventDefault();
                    insertBrToMessage();
                    return;
                }

                handlePressArrows(event);

                if (event.key === '@') {
                    // Окно с поиском показываем только если предыдущий символ - пробел или перенос строки
                    if (hasSymbolBeforeCaret()) {
                        return;
                    }

                    event.preventDefault();
                    removeUnusedMention();

                    // Используем em, так как span в некоторых случая просто не вставляется
                    // Вставка em может привести к появлению дополнительныз стилей в Safari, все они выреазаются в getNewMessageText()
                    editorRef.value?.runCmd('insertHTML', '<em id="new-user-mention">@</em>');

                    searchUsersRef.value?.show(getLeftPositionCursor());
                    return;
                }

                if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                    if (!event.shiftKey) {
                        event.preventDefault();
                        const isMentionProcess = searchUsersRef.value?.isVisible;

                        // Если мы в режиме поиска пользотвателя для упоминания
                        // то по Enter выбираем пользователя из списка
                        if (isMentionProcess) {
                            const isSuccess = searchUsersRef.value?.selectActiveUser();

                            if (!isSuccess) {
                                removeUnusedMention();
                                sendMessage();
                            }
                        } else {
                            sendMessage();
                        }
                    }
                }

                if (event.code === 'Backspace') {
                    let text = Common.stripTags(newMessage.value)?.trim() ?? '';

                    const range = window.getSelection()?.rangeCount && window.getSelection()!.rangeCount > 0 ? window.getSelection()?.getRangeAt(0) : undefined;

                    if (range && range.collapsed) {
                        text = range.startContainer.textContent?.substring(0, range.startOffset) ?? '';
                        text = text.split(/\b/g).pop() ?? '';

                        if (text === '@') {
                            removeUnusedMention();
                        }
                    } else if (text[text.length - 1] === '@') {
                        removeUnusedMention();
                    }
                }
            }

            function onKeyup(event: KeyboardEvent): void {
                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    searchUsersRef.value?.navigate(event.key);
                } else {
                    const newMentionEl = editorRef.value?.getContentEl().querySelector(newMentionSelector);

                    if (newMentionEl && newMentionEl.textContent) {
                        userNameMention.value = newMentionEl.textContent.substring(1);
                    } else {
                        userNameMention.value = '';
                    }
                }
            }

            // Получить отступ курсора слева в сообщении на нужной строке
            function getLeftPositionCursor(): number {
                const contentEl = editorRef.value!.getContentEl() as HTMLDivElement;
                const newMentionEl = contentEl.querySelector(newMentionSelector);

                // Есть элемент упоминания, то определяем точно
                if (newMentionEl) {
                    return (newMentionEl as HTMLElement).offsetLeft;
                } else {
                    // если его нет (хотя неизвестно почему его может не быть, просто на всякий случай)
                    // то пытаемся вычислить вручную, не но во всех случаях это вычисление точно

                    // так как </div> работает как перенос строки заменяем его на br
                    // что бы корректно разбить построчно
                    // и получить длинну строки где сейчас находится курсор что бы спозиционировать блок поиска
                    // пользователь может ткнуть курсор в любое место и делать там упоминание
                    // но в большистве случаев это будет последняя строка
                    const newMessageText = newMessage.value.replace('</div>', '<br>');
                    const items = newMessageText.split('<br>').filter((x: string) => !!x);
                    let countLetters = 0;

                    if (items.length) {
                        const partMessage = Common.stripTags(items[items.length - 1]);
                        countLetters = partMessage ? partMessage.length : 0;
                    }

                    // считаем что 1 символ занимает 6 пикселей
                    return countLetters * 6;
                }
            }

            function hasSymbolBeforeCaret(): boolean {
                const range = window.getSelection()?.getRangeAt(0);

                if (range && range.collapsed) {
                    let text = range.startContainer.textContent?.substring(0, range.startOffset) ?? '';
                    text = text.split(/\b/g).pop() ?? '';

                    if ((range.startContainer as HTMLElement).innerText) {
                        return text[text.length - 1] === '\n';
                    } else if (text.length) {
                        const last = text[text.length - 1];
                        return last !== ' ' && last !== String.fromCharCode(160);
                    } else {
                        return false;
                    }
                }
                return false;
            }

            function insertBrToMessage(): void {
                const browser = Bowser.getParser(window.navigator.userAgent);
                const browserName = browser.getBrowserName();

                removeUnusedMention();

                // кроссбраузероне решение переноса строк, просто добавление <br> ничего не даёт
                // нужно добавлять еще элементы, чтобы было место куда встать курсору
                // такое сочетания элементов было подсмотрено у q-editor
                if (browserName === 'Firefox') {
                    editorRef.value?.runCmd('insertHTML', '<br><div></div>');
                } else if (browserName === 'Safari') {
                    editorRef.value?.runCmd('insertHTML', '<div><br></div>');
                } else {
                    // для хрома и остальных браузеров
                    editorRef.value?.runCmd('insertText', '\n');
                }
            }

            function removeUnusedMention(): void {
                const newMentionEl = editorRef.value?.getContentEl().querySelector(newMentionSelector);
                userNameMention.value = '';

                // Если есть незаконченное упоминание и мы начинаем новое, то удаляем старое
                if (newMentionEl) {
                    newMentionEl.removeAttribute('id');
                    searchUsersRef.value?.hide();
                }
            }

            function attachFiles(): void {
                files.value = dropzoneRef.value?.getAcceptedFiles().map((x: IDropzoneFile) => {
                    return {
                        id: x.fileId,
                        fileName: x.name,
                        path: x.dataURL || '',
                        secretKey: x.secretKey,
                        size: x.size,
                        previewForDropzone: x.dataURL || '',
                    };
                }) ?? [];

                messageFiles.value = files.value.map((file: IFile) => {
                    return {
                        id: file.id,
                        secretKey: file.secretKey
                    };
                });
            }

            function sendMessage(): void {
                // отправляем сообщение если есть текст или файл
                if (hasTextMessage.value || messageFiles.value.length) {
                    context.emit('on-send-message');

                    const data: ChatMessageHubDto = getChatMessageHubDto();

                    if (replyingMessage.value) {
                        data.answerMessageId = replyingMessage.value.id;
                    }

                    if (editingMessage.value) {
                        data.id = editingMessage.value.id;
                    }

                    data.text = getNewMessageText(data.text);

                    // Если мы у нас есть this.mainMessageInThread но нет discussion?.id
                    // значит мы этим сообщением должны создать чат обсуждения
                    if (props.isThreadChat && mainMessageInThread.value && !mainMessageInThread.value.discussion?.id) {
                        data.parentMessageId = mainMessageInThread.value.id;
                    }

                    if (data.id) {
                        (app?.appContext.config.globalProperties.$commonHub as ChatPartialHub)?.editMessageAsync(data);
                    } else {
                        // если мы находимся в чате решения, но у нас нет id чата
                        // значит мы находимся в чате, которого на самом деле нет и нам
                        // его надо создать, и не только его а ещё и решение
                        if (chatId.value === 0 && props.isActivitySolutionChat) {
                            context.emit('create-chat-and-solution', data);
                        } if (chatId.value === 0 && props.userDataForNewPrivateChat) {
                            // если мы находимся в приватном чате, но у нас нет id чата
                            // значит мы находимся в чате, которого на самом деле нет и нам его надо создать
                            context.emit('create-private-chat', data);
                        } else {
                            addNewMessageToChat(data);
                            (app?.appContext.config.globalProperties.$commonHub as ChatPartialHub)?.sendMessageAsync(data, onSendMessageError);
                        }
                    }

                    chatStore.editingMessage = null;
                    chatStore.replyingMessage = null;
                    newMessage.value = '';
                    files.value = [];
                    messageFiles.value = [];
                }
            }

            // Обработчик ошибок при отправке сообщений
            // (пропал интеренет, сервер не ответил и тд)
            function onSendMessageError(message: ChatMessageHubDto, error: any): void {
                context.emit('on-send-message-error', {
                    id: message.id,
                    frontendId: message.frontendId,
                    chatId: message.chatId,
                    error: error.message
                });
            }

            function getNewMessageText(text: string): string {
                let newText = text.replace(/&nbsp;/g, ' ');
                // удаляем класс для вставки кода, если такой остался
                newText = newText.replace(/ class="new-code-selection"/g, '');
                // В Firefox по (ctrl+enter) перенос получается <br><div> - поэтому пытаемся сперва заменить это сочетание
                // В сафари и в ОС Android перенос строки - это div, то делаем из него < br >
                newText = newText.replace(/(<\/div>)/g, '');

                // в firefox при нескольких переносах строки, если удалять переносы, то остаются <div> без <br> получается <br><div><div><div>...
                // так как заранее не ивестно количество таких <div> - цикл
                while (newText.indexOf('<div><div>') !== -1) {
                    newText = newText.replace(/(<div><div>)/g, '<div>');
                }
                newText = newText.replace(/(<br><div>)/g, '<br class="nr">');
                newText = newText.replace(/(<div>)/g, '<br class="nr">');
                newText = newText.replace(/(<br>)/g, '<br class="nr">');

                // удаляем неиспользованное упоминание
                newText = newText.replace(/ id="new-user-mention"/g, '');
                // Заменяем em на span, для единообразия использованных и неиспользованных упоминаний
                newText = newText.replace(/<em/g, '<span');
                newText = newText.replace(/<\/em>/g, '</span>');

                // по идеи единственные span в тексте сообщения - это наши упоминания
                // превращаем их в div (потому что раньше был div и надо это сохранить)
                // а почему мы используем span (em в Сафари) написано в onSelectUser
                newText = newText.replace(/<span([^>]+)>(@[^<,.*]+)<\/span>/g, '<div$1>$2</div>');

                // Удаляем картинки, добавленные при упоминаниях
                newText = newText.replace(/<img(\s?)(\/?)>/g, '');
                // Удаляем то, что могло появиться при копировании/вставке из чата
                newText = newText.replace(/ face="Inter, Helvetica Neue, Arial, Noto Color Emoji, sans-serif"/g, '');

                if (isSafariBrowser()) {
                    // Удаляем всё лишнее, что могло появиться в сафари
                    newText = newText.replace(/ style="font-family: sans-serif;"/g, '');
                    newText = newText.replace(/ face="sans-serif"/g, '');
                    newText = newText.replace(/<i/g, '<span');
                    newText = newText.replace(/<\/i>/g, '</span>');
                }

                newText = newText.replace(/<font/g, '<span');
                newText = newText.replace(/<\/font>/g, '</span>');

                // Есть случай когда возникает еще один лишний <span - когда сперва вставляем перенос строки,
                // пишем текст на новой строке, а затем стираем перенос строки. Тот текс, что был на новой строке попадает в span
                newText = newText.replace(/<span[^>]+>(.*)<\/span>/g, '$1');

                return newText;
            }

            function addNewMessageToChat(message: ChatMessageHubDto): void {
                const newMessageModel: IChatMessage = getNewMessageModel(message);
                context.emit('add-new-message-to-chat', newMessageModel);
            }

            async function pastFiles(event: ClipboardEvent): Promise<void> {
                const items = event.clipboardData?.items;
                if (!items) {
                    return;
                }
                for (const index in items) {
                    const item = items[index];
                    if (item.kind === 'file') {
                        // Добавляем файл в инстанс дропзоны
                        const file = item.getAsFile() as DropzoneFile;
                        if (file) {
                            if (!isShowFileWindow.value) {
                                // Если модал с дропзоной закрыт - открываем
                                isShowFileWindow.value = true;
                            }
                            // Делаем это асинхронно, так как инстанс дропзоны должен проинициализироваться
                            await nextTick();
                            dropzoneRef.value?.addFile(file);
                        }
                    }
                }
            }

            function onPaste(event: ClipboardEvent): void {
                event.preventDefault();
                event.stopPropagation();

                pastFiles(event);

                if (event.clipboardData) {
                    const pastedData = event.clipboardData.getData('text/html');

                    if (pastedData) {
                        // в буфере может быть html, поэтому вырезаем всё,
                        // кроме переносов и ссылок, это могут быть упоминания
                        const clearedHtml = sanitizeHtml(pastedData, {
                            allowedTags: ['a', 'br'],
                            allowedAttributes: {
                                'a': ['href', {
                                    name: 'class',
                                    values: ['userBlockName userBlockName_current', 'userBlockName']
                                }],
                                'br': [{
                                    name: 'class',
                                    values: ['nr']
                                }]
                            }
                        })
                            .trim()
                            .replace(/[\n\r]/g, ' ');

                        const text = convertLinkToMention(clearedHtml);
                        editorRef.value?.runCmd('insertHTML', text);
                    } else {
                        editorRef.value?.runCmd('insertText', event.clipboardData.getData('text/plain'));
                    }
                }
            }

            function convertLinkToMention(html: string): string {
                const links = html.match(/\<a (.*?)>(.*?)\<\/a\>/g);

                // конвертируем ссылки на пользователей в формат упоминания,
                // который нужен для сообщения
                if (links && links.length) {
                    for (let i = 0; i < links.length; i++) {
                        const text = Common.stripTags(links[i]) || '';

                        // упоминание должно быть такое
                        // <div id="24" name="Корзинкина Кира" class="userBlockName">@Корзинкина Кира</div>
                        if (links[i].search('userBlockName') > -1) {
                            const hrefMatches = links[i].match(/<a href="(.*?)"/);
                            let userId = '';

                            if (hrefMatches && hrefMatches[1]) {
                                const parts = hrefMatches[1].split('/');
                                userId = parts[parts.length - 1];
                            }

                            html = html.replace(links[i], `<div id="${userId}" name="${text.substr(1)}" class="userBlockName">${text}</div>`);
                        } else {
                            html = html.replace(links[i], text);
                        }
                    }

                    return convertLinkToMention(html);
                } else {
                    return html;
                }
            }

            function selectEmoji(emoji: any): void {
                if (!editorRef.value) {
                    return;
                }

                const caretPosition = editorRef.value.caret.range?.endOffset;

                // Если каретка не в конце текста, то просто вставляем emoji
                if (!caretPosition || newMessage.value.length !== caretPosition) {
                    editorRef.value.runCmd('insertText', emoji.native);
                    return;
                }

                // Если каретка в конце текста то добавляем пробел,
                // затем перемещаем каретку назад перед пробелом и только потом вставляем emoji
                newMessage.value = newMessage.value + ' ';
                nextTick(() => {
                    if (editorRef.value?.caret.range) {
                        const range = editorRef.value.caret.range.cloneRange();
                        if (range.endContainer.lastChild) {
                            range.setStart(range.startContainer, 1);
                            range.setEnd(range.endContainer.lastChild, caretPosition);
                            editorRef.value?.caret.restore(range);
                        }
                    }
                    editorRef.value?.runCmd('insertText', emoji.native);
                });
            }

            function onSelectUser(user: ChatMembersDto): void {
                const newMentionEl = editorRef.value?.getContentEl().querySelector(newMentionSelector);

                if (newMentionEl) {
                    newMentionEl.remove();
                }

                const fullName = user.lastName + ' ' + user.firstName;

                // Если не добавлять пустой символ, то при удалении проблела последующий текст становится частью упоминания
                const zeroWidthSpaceCode = '&#8203;';
                const mentionHtml = `<span id="${user.id}" name="${fullName}" class="userBlockName">@${fullName}</span>${zeroWidthSpaceCode}&nbsp;`;

                // тут мы используем тег span а не div
                // потому что бразуеры могут div сами превращать в span
                // и добавлять к ним левые стили
                // в общем лучше использовать строчные, а не блочные элементы
                // Так же при использовании div пустой символ не помогает
                editorRef.value?.runCmd(
                    'insertHTML',
                    `${mentionHtml}`
                );

                userNameMention.value = '';
            }

            function isSafariBrowser(): boolean {
                return Bowser.getParser(window.navigator.userAgent).getBrowserName() === 'Safari';
            }

            function hideSearchUsers(): void {
                if (searchUsersRef.value) {
                    searchUsersRef.value?.hide();
                }
            }

            function saveFilesByEnter(event: KeyboardEvent): void {
                if (isShowFileWindow.value && event.key === 'Enter' && isUploadCompleted.value) {
                    attachFiles();
                    isShowFileWindow.value = false;
                }
            }

            function getFullUserName(author: UserBaseInfoDto): string {
                return Common.getFullName(author);
            }

            // Сохраняем высоту блока сообщения
            function updateTextFieldHeight(): void {
                if (!editorRef.value) {
                    return;
                }

                let height = editorRef.value.$el.clientHeight;

                if (isInlineModeChat.value) {
                    // 37px - высота блока с кнопками, когда они под текстовым полем
                    height += 37;
                }

                chatStore.newMessageBlockHeight = height;
            }

            function setFocusToMessageEditor(): void {
                // ставим фокус если сообщение пустое
                // потому что если оно не пустое, значит есть черновик
                // и фокус будет проставлен в initMessageDrafts()
                if (!newMessage.value) {
                    editorRef.value?.focus();
                }
            }

            function getChatMessageHubDto(): ChatMessageHubDto {
                return {
                    chatId: chatId.value,
                    text: newMessage.value,
                    messageFiles: messageFiles.value,
                    frontendId: Common.makeFakeId(),
                    forcedPushNotificationUserIds: Common.getForcedPushNotificationUserIds(newMessage.value)
                };
            }

            function getNewMessageModel(message: ChatMessageHubDto): IChatMessage {
                return {
                    id: -1,
                    frontendId: message.frontendId,
                    chatId: chatId.value,
                    createDateTime: DateUtil.trimTimeZone(new Date().toISOString()), // Получаем строку UTC времени, но удаляем из нее Z, чтобы браузер не добавлял часовой пояс
                    type: ChatMessageType.Ordinal,
                    isEditedMessage: false,
                    isPinnedMessage: false,
                    isDeleted: false,
                    text: message.text,
                    files: [],
                    isMyMessage: true,
                    isSending: true,
                    author: {
                        id: accountInfo.value?.id ?? 0,
                        firstName: accountInfo.value?.firstName ?? '',
                        lastName: accountInfo.value?.lastName ?? '',
                    },
                    chatType: ChatType.Private,
                    messageFiles: message.messageFiles || null,
                    receiverNotInDiscussion: false,
                    forcedPushNotificationUserIds: [],
                };
            }

            function buildKeyForDraftMessage(): number | null | undefined {
                if (props.isActivitySolutionChat) {
                    let activityId: number | undefined | null = props.activityId;
                    let studentId: number | undefined | null = props.student ? props.student.id : null;

                    //  в миничате достаем инфррмауцию из стора
                    if (!activityId) {
                        const selectedSolution: ListChatDto | null = chatStore.selectedSolutionChat;

                        if (selectedSolution) {
                            activityId = selectedSolution.activityId;
                            studentId = selectedSolution.userId;
                        }
                    }

                    // для чатов решений делаем ключ activityId + student.id
                    // что бы сохранять черновики для не созданных чатов
                    if (activityId && studentId) {
                        return Number(activityId.toString() + studentId.toString());
                    } else {
                        return null;
                    }
                } else if (props.isThreadChat) {
                    // для тредов ключ - id главного сообщения, потому что чат тоже может быть не создан
                    return mainMessageInThread.value?.id;
                } else {
                    return props.chatInfo?.id;
                }
            }

            function updateDraftMessage(key?: number | null): void {
                // На странице звонка черновики в чате не сохраняем
                if (isCallPage.value) {
                    return;
                }

                const keyStore = key ?? buildKeyForDraftMessage();

                // сохраняем черновик если есть текст или файл, и это не редактируемое сообщение
                if (!editingMessage.value && (hasTextMessage.value || messageFiles.value.length)) {
                    const data: ChatMessageHubDto = getChatMessageHubDto();
                    const newMessageModel: IChatMessage = getNewMessageModel(data);

                    if (files.value.length) {
                        newMessageModel.filesForDropzone = files.value.map((x: IFile) => {
                            const file = { ...x };
                            file.path = '';
                            file.previewForDropzone = '';
                            return file;
                        });
                    }

                    if (replyingMessage.value) {
                        newMessageModel.answerToMessage = replyingMessage.value;
                    }

                    chatStore.saveDraftMessage({
                        key: keyStore,
                        message: newMessageModel
                    });
                } else {
                    // удаляем черновик
                    if (keyStore) {
                        chatStore.deleteDraftMessage(keyStore);
                    }
                }

                newMessage.value = '';
                files.value = [];
            }

            function initMessageDrafts(): void {
                // На странице звонка черновики для чата не применяем
                if (isCallPage.value) {
                    return;
                }

                const drafts: IDraftInfo | null = chatStore.drafts;

                if (drafts) {
                    const keyStore = buildKeyForDraftMessage();
                    const draft: IDraftMessage | undefined = keyStore ? drafts[keyStore] : undefined;

                    if (draft) {
                        // Храним черновики три дня
                        if ((Date.now() - draft.time) / 1000 / 60 / 60 >= 72) {
                            const draftFiles = draft.message.messageFiles || [];
                            chatStore.deleteDraftMessage(keyStore!);

                            draftFiles.forEach((x: MessageFile) => {
                                new FileClient(getApiClientInitialParams()).removeFile(x.secretKey);
                            });
                        } else {
                            newMessage.value = draft.message.text;
                            messageFiles.value = draft.message.messageFiles || [];
                            files.value = [];

                            draft.message.filesForDropzone?.forEach((x: IFile) => {
                                files.value.push(x);
                            });

                            if (draft.message.answerToMessage) {
                                chatStore.replyingMessage = draft.message.answerToMessage;
                            }

                            if (props.isThreadChat) {
                                const draftMessage = newMessage.value;
                                // Удаляем что бы не было дублирования текста
                                newMessage.value = '';
                                // при открытии треда ставим курсор в конец сообщения
                                nextTick(() => {
                                    editorRef.value?.runCmd('insertHTML', draftMessage);
                                });
                            }

                            nextTick(updateTextFieldHeight);
                        }
                    }
                }
            }

            function updateEventDraft(): void {
                const draftMessage = newMessage.value;
                updateDraftMessage();
                newMessage.value = draftMessage;
            }

            // Проверяем изменился ли токен в localStorage.
            // Измениться он может когда мы вошли под пользователем в другой вкладке
            function isChangeToken(): boolean {
                const vuex = localStorage.getItem('authorizationStore');

                if (vuex) {
                    const vuexData: AuthorizationStoreInterface = JSON.parse(vuex);

                    if (vuexData && app?.appContext.config.globalProperties.$token) {
                        const storeToken = vuexData.jwtToken;
                        const currentToken = app?.appContext.config.globalProperties.$token;

                        if (storeToken !== currentToken) {
                            // Дополнительно обновим токен в localStorage через Vuex
                            useAuthorizationStore().setToken(storeToken);
                        }

                        return storeToken !== currentToken;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }

            // Обертка в виде функции нужна, что бы линтер не ругался на тип
            // тк если мы addEventListener передадим сразу updateDraftMessage
            // то в неё будет передавать event события
            function saveDraftOnOnloadWindow(): void {
                if (isChangeToken()) {
                    return;
                }

                if (isNeedSaveOnUnloadPage) {
                    updateDraftMessage();
                }
            }

            function setFocusToTextField(): void {
                setTimeout(function () {
                    const editorElement = editorRef.value?.getContentEl() as HTMLDivElement;

                    if (editorElement) {
                        editorElement.focus();
                        window?.getSelection()?.selectAllChildren(editorElement);
                        window?.getSelection()?.collapseToEnd();
                    }
                }, 100);
            }

            function insertCode(): void {
                const startOffset = editorRef.value?.caret.range?.startOffset ?? 0;
                const endOffset = editorRef.value?.caret.range?.endOffset ?? 0;
                const text = editorRef.value?.caret.range?.startContainer.textContent ?? '';
                const selection = text.substring(startOffset, endOffset);
                const editorEl = editorRef.value?.getContentEl();
                const prevSelection = editorEl?.querySelector('.new-code-selection');
                const range = editorRef.value?.caret.range;

                if (prevSelection) {
                    prevSelection.removeAttribute('class');
                }

                editorRef.value?.runCmd('cut');

                if (range) {
                    insertBrToMessage();
                }

                editorRef.value?.runCmd('insertHTML', '<div>```</div>');
                insertBrToMessage();

                let codeStartTag = '<div class="new-code-selection">';

                if (isSafariBrowser()) {
                    codeStartTag += '&nbsp;';
                }

                editorRef.value?.runCmd('insertHTML', codeStartTag + selection + '</div>');

                insertBrToMessage();
                editorRef.value?.runCmd('insertHTML', '<div>```</div>');
                insertBrToMessage();

                const insertedSelection = editorEl?.querySelector('.new-code-selection');

                if (insertedSelection) {
                    const newRange = new Range();
                    newRange.setStart(insertedSelection, 0);
                    newRange.setEnd(insertedSelection, 0);
                    editorRef.value?.caret.restore(newRange);
                    insertedSelection.removeAttribute('class');
                }
            }

            onMounted(() => {
                currentRouteName = $route.name?.toString() || '';

                initMessageDrafts();
                updateTextFieldHeight();
                window.addEventListener('click', hideSearchUsers);
                document.addEventListener('keyup', saveFilesByEnter);

                // Принимаем событие из шапки чата, когда открыли страницу решения в новой вкладке
                // Или когда выбрали другой чат решения из выпадающего списка
                chatBus.on(ChatBusEvents.SaveDraftMessage, updateEventDraft);

                // Принимаем события из других страниц, когда входим под другим пользователей
                // тк в этом случае нам надо удалить черновики и не нужно
                // чтобы отрабатывало событие beforeunload
                chatBus.on(ChatBusEvents.NotSavedDraftOnUnload, function () {
                    isNeedSaveOnUnloadPage = false;
                });

                // Сохраняем черновик при обновлении страницы
                window.addEventListener('beforeunload', saveDraftOnOnloadWindow);
            });

            onBeforeUnmount(() => {
                if (props.isActivitySolutionChat) {
                    const selectedSolution: ListChatDto | null = chatStore.selectedSolutionChat;

                    // в чате решения обновляем черновик только если переданы проперти
                    // тк в миничате их нет, там информация берется из стора
                    // для остальных чатов он сохраняется в setChatId
                    if (props.student && props.activityId) {
                        updateDraftMessage();

                        if (currentRouteName !== $route.name) {
                            chatStore.selectedSolutionChat = null;
                        }
                    } else if (selectedSolution) {
                        // В миничате при дестрое обновляем только если уходим на другую страницу
                        // в других случаях обновление идет в вотчере
                        if (currentRouteName !== $route.name) {
                            updateDraftMessage();
                            chatStore.selectedSolutionChat = null;
                        }
                    }
                } else {
                    // При дестрое сохраняем только когда уходим со страницы на другую страницу
                    if (currentRouteName !== $route.name) {
                        updateDraftMessage();
                        chatStore.selectedSolutionChat = null;
                    }
                }

                chatBus.off(ChatBusEvents.SaveDraftMessage);
                chatBus.off(ChatBusEvents.NotSavedDraftOnUnload);

                window.removeEventListener('click', hideSearchUsers);
                window.removeEventListener('keyup', saveFilesByEnter);
                window.removeEventListener('beforeunload', saveDraftOnOnloadWindow);
            });

            return {
                searchUsersRef,
                editorRef,
                dropzoneRef,
                ColorValiablesNameEnum,
                newMessage,
                isShowFileWindow,
                files,
                messageFiles,
                isUploadCompleted,
                userNameMention,
                hasTextMessage,
                onChangeMessage,
                isSelfChat,
                placeholder,
                isInlineModeChat,
                chatId,
                isSearchInMobileChat,
                editingMessage,
                replyingMessage,
                mainMessageInThread,
                accountInfo,
                onDeletedFile,
                cancelWriteMessage,
                onKeydown,
                onKeyup,
                attachFiles,
                sendMessage,
                insertCode,
                onPaste,
                selectEmoji,
                onSelectUser,
                removeUnusedMention,
                localize,
                getFullUserName,
                setFocusToMessageEditor,
                updateDraftMessage,
                getRoleIconName,
                setFocusToTextField,
            };
        }
    });
</script>

<style lang="scss" scoped>
    ::-webkit-scrollbar {
        width: 10px;

        &-track {
            background: white;
        }

        &-thumb {
            background: rgba(0, 0, 0, .2) !important;

            &:hover {
                background: rgba(0, 0, 0, .3) !important;
            }
        }
    }

    .bottom-panel-component {
        position: relative;
        background-color: #fff;

        ::v-deep(.q-editor__content) {
            max-height: 30vh;

            * {
                font-family: 'Inter', 'Helvetica Neue', 'Arial', sans-serif !important;
                font-style: normal !important;
            }
        }

        .inline-editor  {
            max-height: 50vh;
            overflow-y: auto;

            ::v-deep(.q-editor__content) {
                padding: 17px 16px !important;

                &:empty:not(:focus):before {
                    color: $shade-7;
                    opacity: 1;
                }
            }
        }

        .editing-message-block {
            max-height: 46px;
            overflow: hidden;
            padding-left: 8px;
            border-left: 2px solid #3e53a4;
        }

        hr {
            border: none;
            color: $shade-2;
            background-color: $shade-2;
            height: 1px;
        }

        .chat-icon {
            margin-right: 16px;
        }

        .user-name {
            margin-bottom: 8px;
            font-size: 10px;
            line-height: 12px;
            color: $success;
        }
    }

    .confirm-modal {
        &.files-modal {
            min-width: 560px;
        }
    }

    @media (max-width: 960px) {
        .confirm-modal {
            &.files-modal {
                width: 98%;
                min-width: 98%;
            }
        }
    }
</style>

<style lang="scss">
    .q-editor-message {
        .userBlockName {
            display: inline;
            text-decoration: none;
        }
    }

    .q-editor__content {
        &:before {
            pointer-events: none;
        }

        img {
            pointer-events: none;
        }
    }
</style>
